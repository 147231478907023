<template>
  <div>
    <div :id="name"></div>
  </div>
</template>

<script>
import wangEditor from "wangeditor";

/**
 * 父组件可传入参数
 * name -> 标签 id
 * uploadImgServer -> 上传图片的接口 返回数据格式需严格按照文档要求，目前的接口已配好可以直接使用，完全自定义上传参考文档
 * value -> 默认值
 */

export default {
  name: "Editor",
  props: {
    name: { type: String, default: "editor" },
    uploadImgServer: {
      type: String,
      default: `${process.env.VUE_APP_BASE_API}/upload/img`,
    },
    value: { type: String, default: "" },
    height: { type: Number, default: 500 },
  },

  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value: {
      handler(newVal) {
        this.value = newVal;
        if (this.editor) this.editor.txt.html(newVal);
      },
      immediate: true,
    },
    editor: {
      handler() {
        this.editor.txt.html(this.value);
      },
    },
  },
  mounted() {
    // console.log("process", process.env);
    this.editor = new wangEditor(`#${this.name}`);
    this.editor.config.height = this.height;
    // this.editor.config.focus = false;
    // this.editor.config.placeholder = "点击工具栏按钮可开启全屏编辑...";
    this.editor.config.uploadImgServer = this.uploadImgServer;
    this.editor.config.uploadImgMaxSize = 3 * 1024 * 1024;
    this.editor.config.uploadImgMaxLength = 1;
    this.editor.config.uploadFileName = "file";
    this.editor.config.uploadImgHooks = this.uploadHandle;
    this.editor.config.showLinkImg = false;
    this.editor.config.debug = true;
    this.editor.config.onchange = this.updateContent;
    this.editor.config.zIndex = 0;
    this.editor.config.uploadFileName = "file";
    this.editor.config.uploadImgParams = { path: "img/" };
    // this.editor.config.uploadImgHeaders = {
    //   'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").content,
    //   Accept: 'application/json',
    // }
    this.editor.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "quote",
      "emoticon",
      "image",
      // "video",
      "table",
      // "code",
      "splitLine",
      "undo",
      "redo",
    ];
    this.editor.create();
    document.querySelector(".placeholder").style.zIndex = 1;
    document.querySelector(".placeholder").innerText = "点击工具栏按钮可开启全屏编辑...";
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy();
    this.editor = null;
  },
  methods: {
    updateContent(html) {
      this.$emit("update-value", this.editor.txt.html(), this.name);
    },
    uploadHandle() {
      return {
        before: function (xhr, editor, files) {},
        success: function (xhr, editor, result) {},
        fail: function (xhr, editor, result) {},
        error: function (xhr, editor) {},
        timeout: function (xhr, editor) {},
        customInsert: function (insertImg, result, editor) {},
      };
    },
  },
};
</script>

<style lang="less" scoped>
p {
  white-space: normal !important;
  font: 16px;
  line-height: 28px;
}
</style>